import React, { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";

const instruments = [
  {
    "asset_name": "DOG",
    "bitget_asset": "DOGUSDT",
    "blowfin_asset": "DOG-USDT"
  },
  {
    "asset_name": "AKT",
    "bitget_asset": "AKTUSDT",
    "blowfin_asset": "AKT-USDT"
  },
  {
    "asset_name": "MBOX",
    "bitget_asset": "MBOXUSDT",
    "blowfin_asset": "MBOX-USDT"
  },
  {
    "asset_name": "BANANA",
    "bitget_asset": "BANANAUSDT",
    "blowfin_asset": "BANANA-USDT"
  },
  {
    "asset_name": "BB",
    "bitget_asset": "BBUSDT",
    "blowfin_asset": "BB-USDT"
  },
  {
    "asset_name": "CYBER",
    "bitget_asset": "CYBERUSDT",
    "blowfin_asset": "CYBER-USDT"
  },
  {
    "asset_name": "SFP",
    "bitget_asset": "SFPUSDT",
    "blowfin_asset": "SFP-USDT"
  },
  {
    "asset_name": "CAKE",
    "bitget_asset": "CAKEUSDT",
    "blowfin_asset": "CAKE-USDT"
  },
  {
    "asset_name": "MEME",
    "bitget_asset": "MEMEUSDT",
    "blowfin_asset": "MEME-USDT"
  },
  {
    "asset_name": "ICP",
    "bitget_asset": "ICPUSDT",
    "blowfin_asset": "ICP-USDT"
  },
  {
    "asset_name": "LQTY",
    "bitget_asset": "LQTYUSDT",
    "blowfin_asset": "LQTY-USDT"
  },
  {
    "asset_name": "X",
    "bitget_asset": "XUSDT",
    "blowfin_asset": "X-USDT"
  },
  {
    "asset_name": "BNT",
    "bitget_asset": "BNTUSDT",
    "blowfin_asset": "BNT-USDT"
  },
  {
    "asset_name": "CRV",
    "bitget_asset": "CRVUSDT",
    "blowfin_asset": "CRV-USDT"
  },
  {
    "asset_name": "TRX",
    "bitget_asset": "TRXUSDT",
    "blowfin_asset": "TRX-USDT"
  },
  {
    "asset_name": "1000RATS",
    "bitget_asset": "1000RATSUSDT",
    "blowfin_asset": "1000RATS-USDT"
  },
  {
    "asset_name": "ORBS",
    "bitget_asset": "ORBSUSDT",
    "blowfin_asset": "ORBS-USDT"
  },
  {
    "asset_name": "1000BONK",
    "bitget_asset": "1000BONKUSDT",
    "blowfin_asset": "1000BONK-USDT"
  },
  {
    "asset_name": "CHZ",
    "bitget_asset": "CHZUSDT",
    "blowfin_asset": "CHZ-USDT"
  },
  {
    "asset_name": "DEEP",
    "bitget_asset": "DEEPUSDT",
    "blowfin_asset": "DEEP-USDT"
  },
  {
    "asset_name": "WAXP",
    "bitget_asset": "WAXPUSDT",
    "blowfin_asset": "WAXP-USDT"
  },
  {
    "asset_name": "AR",
    "bitget_asset": "ARUSDT",
    "blowfin_asset": "AR-USDT"
  },
  {
    "asset_name": "DRIFT",
    "bitget_asset": "DRIFTUSDT",
    "blowfin_asset": "DRIFT-USDT"
  },
  {
    "asset_name": "LUNC",
    "bitget_asset": "LUNCUSDT",
    "blowfin_asset": "LUNC-USDT"
  },
  {
    "asset_name": "GLM",
    "bitget_asset": "GLMUSDT",
    "blowfin_asset": "GLM-USDT"
  },
  {
    "asset_name": "GAS",
    "bitget_asset": "GASUSDT",
    "blowfin_asset": "GAS-USDT"
  },
  {
    "asset_name": "BEAM",
    "bitget_asset": "BEAMUSDT",
    "blowfin_asset": "BEAM-USDT"
  },
  {
    "asset_name": "VANRY",
    "bitget_asset": "VANRYUSDT",
    "blowfin_asset": "VANRY-USDT"
  },
  {
    "asset_name": "RDNT",
    "bitget_asset": "RDNTUSDT",
    "blowfin_asset": "RDNT-USDT"
  },
  {
    "asset_name": "AEVO",
    "bitget_asset": "AEVOUSDT",
    "blowfin_asset": "AEVO-USDT"
  },
  {
    "asset_name": "UMA",
    "bitget_asset": "UMAUSDT",
    "blowfin_asset": "UMA-USDT"
  },
  {
    "asset_name": "XVS",
    "bitget_asset": "XVSUSDT",
    "blowfin_asset": "XVS-USDT"
  },
  {
    "asset_name": "KDA",
    "bitget_asset": "KDAUSDT",
    "blowfin_asset": "KDA-USDT"
  },
  {
    "asset_name": "ENA",
    "bitget_asset": "ENAUSDT",
    "blowfin_asset": "ENA-USDT"
  },
  {
    "asset_name": "CHILLGUY",
    "bitget_asset": "CHILLGUYUSDT",
    "blowfin_asset": "CHILLGUY-USDT"
  },
  {
    "asset_name": "TON",
    "bitget_asset": "TONUSDT",
    "blowfin_asset": "TON-USDT"
  },
  {
    "asset_name": "FLM",
    "bitget_asset": "FLMUSDT",
    "blowfin_asset": "FLM-USDT"
  },
  {
    "asset_name": "CHR",
    "bitget_asset": "CHRUSDT",
    "blowfin_asset": "CHR-USDT"
  },
  {
    "asset_name": "AVAIL",
    "bitget_asset": "AVAILUSDT",
    "blowfin_asset": "AVAIL-USDT"
  },
  {
    "asset_name": "PEPE",
    "bitget_asset": "PEPEUSDT",
    "blowfin_asset": "PEPE-USDT"
  },
  {
    "asset_name": "ONDO",
    "bitget_asset": "ONDOUSDT",
    "blowfin_asset": "ONDO-USDT"
  },
  {
    "asset_name": "UNI",
    "bitget_asset": "UNIUSDT",
    "blowfin_asset": "UNI-USDT"
  },
  {
    "asset_name": "HBAR",
    "bitget_asset": "HBARUSDT",
    "blowfin_asset": "HBAR-USDT"
  },
  {
    "asset_name": "HIPPO",
    "bitget_asset": "HIPPOUSDT",
    "blowfin_asset": "HIPPO-USDT"
  },
  {
    "asset_name": "ARB",
    "bitget_asset": "ARBUSDT",
    "blowfin_asset": "ARB-USDT"
  },
  {
    "asset_name": "KNC",
    "bitget_asset": "KNCUSDT",
    "blowfin_asset": "KNC-USDT"
  },
  {
    "asset_name": "NKN",
    "bitget_asset": "NKNUSDT",
    "blowfin_asset": "NKN-USDT"
  },
  {
    "asset_name": "LUMIA",
    "bitget_asset": "LUMIAUSDT",
    "blowfin_asset": "LUMIA-USDT"
  },
  {
    "asset_name": "KAVA",
    "bitget_asset": "KAVAUSDT",
    "blowfin_asset": "KAVA-USDT"
  },
  {
    "asset_name": "EIGEN",
    "bitget_asset": "EIGENUSDT",
    "blowfin_asset": "EIGEN-USDT"
  },
  {
    "asset_name": "PENDLE",
    "bitget_asset": "PENDLEUSDT",
    "blowfin_asset": "PENDLE-USDT"
  },
  {
    "asset_name": "JOE",
    "bitget_asset": "JOEUSDT",
    "blowfin_asset": "JOE-USDT"
  },
  {
    "asset_name": "PYTH",
    "bitget_asset": "PYTHUSDT",
    "blowfin_asset": "PYTH-USDT"
  },
  {
    "asset_name": "BICO",
    "bitget_asset": "BICOUSDT",
    "blowfin_asset": "BICO-USDT"
  },
  {
    "asset_name": "SCR",
    "bitget_asset": "SCRUSDT",
    "blowfin_asset": "SCR-USDT"
  },
  {
    "asset_name": "LOOKS",
    "bitget_asset": "LOOKSUSDT",
    "blowfin_asset": "LOOKS-USDT"
  },
  {
    "asset_name": "METIS",
    "bitget_asset": "METISUSDT",
    "blowfin_asset": "METIS-USDT"
  },
  {
    "asset_name": "LEVER",
    "bitget_asset": "LEVERUSDT",
    "blowfin_asset": "LEVER-USDT"
  },
  {
    "asset_name": "QNT",
    "bitget_asset": "QNTUSDT",
    "blowfin_asset": "QNT-USDT"
  },
  {
    "asset_name": "STG",
    "bitget_asset": "STGUSDT",
    "blowfin_asset": "STG-USDT"
  },
  {
    "asset_name": "NFP",
    "bitget_asset": "NFPUSDT",
    "blowfin_asset": "NFP-USDT"
  },
  {
    "asset_name": "PHB",
    "bitget_asset": "PHBUSDT",
    "blowfin_asset": "PHB-USDT"
  },
  {
    "asset_name": "UXLINK",
    "bitget_asset": "UXLINKUSDT",
    "blowfin_asset": "UXLINK-USDT"
  },
  {
    "asset_name": "GMT",
    "bitget_asset": "GMTUSDT",
    "blowfin_asset": "GMT-USDT"
  },
  {
    "asset_name": "LSK",
    "bitget_asset": "LSKUSDT",
    "blowfin_asset": "LSK-USDT"
  },
  {
    "asset_name": "MANA",
    "bitget_asset": "MANAUSDT",
    "blowfin_asset": "MANA-USDT"
  },
  {
    "asset_name": "ALPACA",
    "bitget_asset": "ALPACAUSDT",
    "blowfin_asset": "ALPACA-USDT"
  },
  {
    "asset_name": "AERO",
    "bitget_asset": "AEROUSDT",
    "blowfin_asset": "AERO-USDT"
  },
  {
    "asset_name": "BSW",
    "bitget_asset": "BSWUSDT",
    "blowfin_asset": "BSW-USDT"
  },
  {
    "asset_name": "EGLD",
    "bitget_asset": "EGLDUSDT",
    "blowfin_asset": "EGLD-USDT"
  },
  {
    "asset_name": "MANTA",
    "bitget_asset": "MANTAUSDT",
    "blowfin_asset": "MANTA-USDT"
  },
  {
    "asset_name": "TAO",
    "bitget_asset": "TAOUSDT",
    "blowfin_asset": "TAO-USDT"
  },
  {
    "asset_name": "ASTR",
    "bitget_asset": "ASTRUSDT",
    "blowfin_asset": "ASTR-USDT"
  },
  {
    "asset_name": "ALPHA",
    "bitget_asset": "ALPHAUSDT",
    "blowfin_asset": "ALPHA-USDT"
  },
  {
    "asset_name": "TRU",
    "bitget_asset": "TRUUSDT",
    "blowfin_asset": "TRU-USDT"
  },
  {
    "asset_name": "INJ",
    "bitget_asset": "INJUSDT",
    "blowfin_asset": "INJ-USDT"
  },
  {
    "asset_name": "ZRO",
    "bitget_asset": "ZROUSDT",
    "blowfin_asset": "ZRO-USDT"
  },
  {
    "asset_name": "SANTOS",
    "bitget_asset": "SANTOSUSDT",
    "blowfin_asset": "SANTOS-USDT"
  },
  {
    "asset_name": "LPT",
    "bitget_asset": "LPTUSDT",
    "blowfin_asset": "LPT-USDT"
  },
  {
    "asset_name": "BRETT",
    "bitget_asset": "BRETTUSDT",
    "blowfin_asset": "BRETT-USDT"
  },
  {
    "asset_name": "RAY",
    "bitget_asset": "RAYUSDT",
    "blowfin_asset": "RAY-USDT"
  },
  {
    "asset_name": "W",
    "bitget_asset": "WUSDT",
    "blowfin_asset": "W-USDT"
  },
  {
    "asset_name": "FLUX",
    "bitget_asset": "FLUXUSDT",
    "blowfin_asset": "FLUX-USDT"
  },
  {
    "asset_name": "HMSTR",
    "bitget_asset": "HMSTRUSDT",
    "blowfin_asset": "HMSTR-USDT"
  },
  {
    "asset_name": "COW",
    "bitget_asset": "COWUSDT",
    "blowfin_asset": "COW-USDT"
  },
  {
    "asset_name": "ZRX",
    "bitget_asset": "ZRXUSDT",
    "blowfin_asset": "ZRX-USDT"
  },
  {
    "asset_name": "ICX",
    "bitget_asset": "ICXUSDT",
    "blowfin_asset": "ICX-USDT"
  },
  {
    "asset_name": "STEEM",
    "bitget_asset": "STEEMUSDT",
    "blowfin_asset": "STEEM-USDT"
  },
  {
    "asset_name": "SSV",
    "bitget_asset": "SSVUSDT",
    "blowfin_asset": "SSV-USDT"
  },
  {
    "asset_name": "SUPER",
    "bitget_asset": "SUPERUSDT",
    "blowfin_asset": "SUPER-USDT"
  },
  {
    "asset_name": "SAND",
    "bitget_asset": "SANDUSDT",
    "blowfin_asset": "SAND-USDT"
  },
  {
    "asset_name": "CORE",
    "bitget_asset": "COREUSDT",
    "blowfin_asset": "CORE-USDT"
  },
  {
    "asset_name": "TROY",
    "bitget_asset": "TROYUSDT",
    "blowfin_asset": "TROY-USDT"
  },
  {
    "asset_name": "GHST",
    "bitget_asset": "GHSTUSDT",
    "blowfin_asset": "GHST-USDT"
  },
  {
    "asset_name": "ALICE",
    "bitget_asset": "ALICEUSDT",
    "blowfin_asset": "ALICE-USDT"
  },
  {
    "asset_name": "ENS",
    "bitget_asset": "ENSUSDT",
    "blowfin_asset": "ENS-USDT"
  },
  {
    "asset_name": "THE",
    "bitget_asset": "THEUSDT",
    "blowfin_asset": "THE-USDT"
  },
  {
    "asset_name": "BIGTIME",
    "bitget_asset": "BIGTIMEUSDT",
    "blowfin_asset": "BIGTIME-USDT"
  },
  {
    "asset_name": "REEF",
    "bitget_asset": "REEFUSDT",
    "blowfin_asset": "REEF-USDT"
  },
  {
    "asset_name": "FIDA",
    "bitget_asset": "FIDAUSDT",
    "blowfin_asset": "FIDA-USDT"
  },
  {
    "asset_name": "DOGS",
    "bitget_asset": "DOGSUSDT",
    "blowfin_asset": "DOGS-USDT"
  },
  {
    "asset_name": "ADA",
    "bitget_asset": "ADAUSDT",
    "blowfin_asset": "ADA-USDT"
  },
  {
    "asset_name": "RENDER",
    "bitget_asset": "RENDERUSDT",
    "blowfin_asset": "RENDER-USDT"
  },
  {
    "asset_name": "MKR",
    "bitget_asset": "MKRUSDT",
    "blowfin_asset": "MKR-USDT"
  },
  {
    "asset_name": "LRC",
    "bitget_asset": "LRCUSDT",
    "blowfin_asset": "LRC-USDT"
  },
  {
    "asset_name": "MYRO",
    "bitget_asset": "MYROUSDT",
    "blowfin_asset": "MYRO-USDT"
  },
  {
    "asset_name": "BLAST",
    "bitget_asset": "BLASTUSDT",
    "blowfin_asset": "BLAST-USDT"
  },
  {
    "asset_name": "FET",
    "bitget_asset": "FETUSDT",
    "blowfin_asset": "FET-USDT"
  },
  {
    "asset_name": "CRO",
    "bitget_asset": "CROUSDT",
    "blowfin_asset": "CRO-USDT"
  },
  {
    "asset_name": "STMX",
    "bitget_asset": "STMXUSDT",
    "blowfin_asset": "STMX-USDT"
  },
  {
    "asset_name": "AI",
    "bitget_asset": "AIUSDT",
    "blowfin_asset": "AI-USDT"
  },
  {
    "asset_name": "VET",
    "bitget_asset": "VETUSDT",
    "blowfin_asset": "VET-USDT"
  },
  {
    "asset_name": "XTZ",
    "bitget_asset": "XTZUSDT",
    "blowfin_asset": "XTZ-USDT"
  },
  {
    "asset_name": "SUNDOG",
    "bitget_asset": "SUNDOGUSDT",
    "blowfin_asset": "SUNDOG-USDT"
  },
  {
    "asset_name": "SKL",
    "bitget_asset": "SKLUSDT",
    "blowfin_asset": "SKL-USDT"
  },
  {
    "asset_name": "HIFI",
    "bitget_asset": "HIFIUSDT",
    "blowfin_asset": "HIFI-USDT"
  },
  {
    "asset_name": "ARK",
    "bitget_asset": "ARKUSDT",
    "blowfin_asset": "ARK-USDT"
  },
  {
    "asset_name": "CELR",
    "bitget_asset": "CELRUSDT",
    "blowfin_asset": "CELR-USDT"
  },
  {
    "asset_name": "ATOM",
    "bitget_asset": "ATOMUSDT",
    "blowfin_asset": "ATOM-USDT"
  },
  {
    "asset_name": "BEL",
    "bitget_asset": "BELUSDT",
    "blowfin_asset": "BEL-USDT"
  },
  {
    "asset_name": "GALA",
    "bitget_asset": "GALAUSDT",
    "blowfin_asset": "GALA-USDT"
  },
  {
    "asset_name": "FOXY",
    "bitget_asset": "FOXYUSDT",
    "blowfin_asset": "FOXY-USDT"
  },
  {
    "asset_name": "WOO",
    "bitget_asset": "WOOUSDT",
    "blowfin_asset": "WOO-USDT"
  },
  {
    "asset_name": "BLUR",
    "bitget_asset": "BLURUSDT",
    "blowfin_asset": "BLUR-USDT"
  },
  {
    "asset_name": "POWR",
    "bitget_asset": "POWRUSDT",
    "blowfin_asset": "POWR-USDT"
  },
  {
    "asset_name": "SPELL",
    "bitget_asset": "SPELLUSDT",
    "blowfin_asset": "SPELL-USDT"
  },
  {
    "asset_name": "DBR",
    "bitget_asset": "DBRUSDT",
    "blowfin_asset": "DBR-USDT"
  },
  {
    "asset_name": "ENJ",
    "bitget_asset": "ENJUSDT",
    "blowfin_asset": "ENJ-USDT"
  },
  {
    "asset_name": "CTSI",
    "bitget_asset": "CTSIUSDT",
    "blowfin_asset": "CTSI-USDT"
  },
  {
    "asset_name": "MAGIC",
    "bitget_asset": "MAGICUSDT",
    "blowfin_asset": "MAGIC-USDT"
  },
  {
    "asset_name": "HOOK",
    "bitget_asset": "HOOKUSDT",
    "blowfin_asset": "HOOK-USDT"
  },
  {
    "asset_name": "SWELL",
    "bitget_asset": "SWELLUSDT",
    "blowfin_asset": "SWELL-USDT"
  },
  {
    "asset_name": "COTI",
    "bitget_asset": "COTIUSDT",
    "blowfin_asset": "COTI-USDT"
  },
  {
    "asset_name": "SHIB",
    "bitget_asset": "SHIBUSDT",
    "blowfin_asset": "SHIB-USDT"
  },
  {
    "asset_name": "TLM",
    "bitget_asset": "TLMUSDT",
    "blowfin_asset": "TLM-USDT"
  },
  {
    "asset_name": "TURBO",
    "bitget_asset": "TURBOUSDT",
    "blowfin_asset": "TURBO-USDT"
  },
  {
    "asset_name": "YGG",
    "bitget_asset": "YGGUSDT",
    "blowfin_asset": "YGG-USDT"
  },
  {
    "asset_name": "IOST",
    "bitget_asset": "IOSTUSDT",
    "blowfin_asset": "IOST-USDT"
  },
  {
    "asset_name": "PNUT",
    "bitget_asset": "PNUTUSDT",
    "blowfin_asset": "PNUT-USDT"
  },
  {
    "asset_name": "PIRATE",
    "bitget_asset": "PIRATEUSDT",
    "blowfin_asset": "PIRATE-USDT"
  },
  {
    "asset_name": "IOTA",
    "bitget_asset": "IOTAUSDT",
    "blowfin_asset": "IOTA-USDT"
  },
  {
    "asset_name": "SYS",
    "bitget_asset": "SYSUSDT",
    "blowfin_asset": "SYS-USDT"
  },
  {
    "asset_name": "FWOG",
    "bitget_asset": "FWOGUSDT",
    "blowfin_asset": "FWOG-USDT"
  },
  {
    "asset_name": "ALGO",
    "bitget_asset": "ALGOUSDT",
    "blowfin_asset": "ALGO-USDT"
  },
  {
    "asset_name": "LIT",
    "bitget_asset": "LITUSDT",
    "blowfin_asset": "LIT-USDT"
  },
  {
    "asset_name": "MINA",
    "bitget_asset": "MINAUSDT",
    "blowfin_asset": "MINA-USDT"
  },
  {
    "asset_name": "PORTAL",
    "bitget_asset": "PORTALUSDT",
    "blowfin_asset": "PORTAL-USDT"
  },
  {
    "asset_name": "ID",
    "bitget_asset": "IDUSDT",
    "blowfin_asset": "ID-USDT"
  },
  {
    "asset_name": "DYDX",
    "bitget_asset": "DYDXUSDT",
    "blowfin_asset": "DYDX-USDT"
  },
  {
    "asset_name": "AXL",
    "bitget_asset": "AXLUSDT",
    "blowfin_asset": "AXL-USDT"
  },
  {
    "asset_name": "GRT",
    "bitget_asset": "GRTUSDT",
    "blowfin_asset": "GRT-USDT"
  },
  {
    "asset_name": "IMX",
    "bitget_asset": "IMXUSDT",
    "blowfin_asset": "IMX-USDT"
  },
  {
    "asset_name": "LINK",
    "bitget_asset": "LINKUSDT",
    "blowfin_asset": "LINK-USDT"
  },
  {
    "asset_name": "POPCAT",
    "bitget_asset": "POPCATUSDT",
    "blowfin_asset": "POPCAT-USDT"
  },
  {
    "asset_name": "TIA",
    "bitget_asset": "TIAUSDT",
    "blowfin_asset": "TIA-USDT"
  },
  {
    "asset_name": "MAX",
    "bitget_asset": "MAXUSDT",
    "blowfin_asset": "MAX-USDT"
  },
  {
    "asset_name": "DOT",
    "bitget_asset": "DOTUSDT",
    "blowfin_asset": "DOT-USDT"
  },
  {
    "asset_name": "IO",
    "bitget_asset": "IOUSDT",
    "blowfin_asset": "IO-USDT"
  },
  {
    "asset_name": "DYM",
    "bitget_asset": "DYMUSDT",
    "blowfin_asset": "DYM-USDT"
  },
  {
    "asset_name": "KAIA",
    "bitget_asset": "KAIAUSDT",
    "blowfin_asset": "KAIA-USDT"
  },
  {
    "asset_name": "PEOPLE",
    "bitget_asset": "PEOPLEUSDT",
    "blowfin_asset": "PEOPLE-USDT"
  },
  {
    "asset_name": "REZ",
    "bitget_asset": "REZUSDT",
    "blowfin_asset": "REZ-USDT"
  },
  {
    "asset_name": "ARKM",
    "bitget_asset": "ARKMUSDT",
    "blowfin_asset": "ARKM-USDT"
  },
  {
    "asset_name": "SPX",
    "bitget_asset": "SPXUSDT",
    "blowfin_asset": "SPX-USDT"
  },
  {
    "asset_name": "SYN",
    "bitget_asset": "SYNUSDT",
    "blowfin_asset": "SYN-USDT"
  },
  {
    "asset_name": "OGN",
    "bitget_asset": "OGNUSDT",
    "blowfin_asset": "OGN-USDT"
  },
  {
    "asset_name": "WLD",
    "bitget_asset": "WLDUSDT",
    "blowfin_asset": "WLD-USDT"
  },
  {
    "asset_name": "G",
    "bitget_asset": "GUSDT",
    "blowfin_asset": "G-USDT"
  },
  {
    "asset_name": "GMX",
    "bitget_asset": "GMXUSDT",
    "blowfin_asset": "GMX-USDT"
  },
  {
    "asset_name": "FXS",
    "bitget_asset": "FXSUSDT",
    "blowfin_asset": "FXS-USDT"
  },
  {
    "asset_name": "ATH",
    "bitget_asset": "ATHUSDT",
    "blowfin_asset": "ATH-USDT"
  },
  {
    "asset_name": "STX",
    "bitget_asset": "STXUSDT",
    "blowfin_asset": "STX-USDT"
  },
  {
    "asset_name": "HNT",
    "bitget_asset": "HNTUSDT",
    "blowfin_asset": "HNT-USDT"
  },
  {
    "asset_name": "ALEO",
    "bitget_asset": "ALEOUSDT",
    "blowfin_asset": "ALEO-USDT"
  },
  {
    "asset_name": "RUNE",
    "bitget_asset": "RUNEUSDT",
    "blowfin_asset": "RUNE-USDT"
  },
  {
    "asset_name": "GTC",
    "bitget_asset": "GTCUSDT",
    "blowfin_asset": "GTC-USDT"
  },
  {
    "asset_name": "MTL",
    "bitget_asset": "MTLUSDT",
    "blowfin_asset": "MTL-USDT"
  },
  {
    "asset_name": "MORPHO",
    "bitget_asset": "MORPHOUSDT",
    "blowfin_asset": "MORPHO-USDT"
  },
  {
    "asset_name": "MEMEFI",
    "bitget_asset": "MEMEFIUSDT",
    "blowfin_asset": "MEMEFI-USDT"
  },
  {
    "asset_name": "CKB",
    "bitget_asset": "CKBUSDT",
    "blowfin_asset": "CKB-USDT"
  },
  {
    "asset_name": "L3",
    "bitget_asset": "L3USDT",
    "blowfin_asset": "L3-USDT"
  },
  {
    "asset_name": "SAFE",
    "bitget_asset": "SAFEUSDT",
    "blowfin_asset": "SAFE-USDT"
  },
  {
    "asset_name": "BAND",
    "bitget_asset": "BANDUSDT",
    "blowfin_asset": "BAND-USDT"
  },
  {
    "asset_name": "PRCL",
    "bitget_asset": "PRCLUSDT",
    "blowfin_asset": "PRCL-USDT"
  },
  {
    "asset_name": "LISTA",
    "bitget_asset": "LISTAUSDT",
    "blowfin_asset": "LISTA-USDT"
  },
  {
    "asset_name": "MEW",
    "bitget_asset": "MEWUSDT",
    "blowfin_asset": "MEW-USDT"
  },
  {
    "asset_name": "SXP",
    "bitget_asset": "SXPUSDT",
    "blowfin_asset": "SXP-USDT"
  },
  {
    "asset_name": "ETC",
    "bitget_asset": "ETCUSDT",
    "blowfin_asset": "ETC-USDT"
  },
  {
    "asset_name": "OM",
    "bitget_asset": "OMUSDT",
    "blowfin_asset": "OM-USDT"
  },
  {
    "asset_name": "ARPA",
    "bitget_asset": "ARPAUSDT",
    "blowfin_asset": "ARPA-USDT"
  },
  {
    "asset_name": "BNX",
    "bitget_asset": "BNXUSDT",
    "blowfin_asset": "BNX-USDT"
  },
  {
    "asset_name": "TNSR",
    "bitget_asset": "TNSRUSDT",
    "blowfin_asset": "TNSR-USDT"
  },
  {
    "asset_name": "JASMY",
    "bitget_asset": "JASMYUSDT",
    "blowfin_asset": "JASMY-USDT"
  },
  {
    "asset_name": "SAGA",
    "bitget_asset": "SAGAUSDT",
    "blowfin_asset": "SAGA-USDT"
  },
  {
    "asset_name": "PROS",
    "bitget_asset": "PROSUSDT",
    "blowfin_asset": "PROS-USDT"
  },
  {
    "asset_name": "WIF",
    "bitget_asset": "WIFUSDT",
    "blowfin_asset": "WIF-USDT"
  },
  {
    "asset_name": "NEAR",
    "bitget_asset": "NEARUSDT",
    "blowfin_asset": "NEAR-USDT"
  },
  {
    "asset_name": "API3",
    "bitget_asset": "API3USDT",
    "blowfin_asset": "API3-USDT"
  },
  {
    "asset_name": "NEO",
    "bitget_asset": "NEOUSDT",
    "blowfin_asset": "NEO-USDT"
  },
  {
    "asset_name": "RPL",
    "bitget_asset": "RPLUSDT",
    "blowfin_asset": "RPL-USDT"
  },
  {
    "asset_name": "APE",
    "bitget_asset": "APEUSDT",
    "blowfin_asset": "APE-USDT"
  },
  {
    "asset_name": "ORDER",
    "bitget_asset": "ORDERUSDT",
    "blowfin_asset": "ORDER-USDT"
  },
  {
    "asset_name": "OP",
    "bitget_asset": "OPUSDT",
    "blowfin_asset": "OP-USDT"
  },
  {
    "asset_name": "MOODENG",
    "bitget_asset": "MOODENGUSDT",
    "blowfin_asset": "MOODENG-USDT"
  },
  {
    "asset_name": "SNX",
    "bitget_asset": "SNXUSDT",
    "blowfin_asset": "SNX-USDT"
  },
  {
    "asset_name": "SCRT",
    "bitget_asset": "SCRTUSDT",
    "blowfin_asset": "SCRT-USDT"
  },
  {
    "asset_name": "ZIL",
    "bitget_asset": "ZILUSDT",
    "blowfin_asset": "ZIL-USDT"
  },
  {
    "asset_name": "KSM",
    "bitget_asset": "KSMUSDT",
    "blowfin_asset": "KSM-USDT"
  },
  {
    "asset_name": "MASK",
    "bitget_asset": "MASKUSDT",
    "blowfin_asset": "MASK-USDT"
  },
  {
    "asset_name": "LOKA",
    "bitget_asset": "LOKAUSDT",
    "blowfin_asset": "LOKA-USDT"
  },
  {
    "asset_name": "DIA",
    "bitget_asset": "DIAUSDT",
    "blowfin_asset": "DIA-USDT"
  },
  {
    "asset_name": "SLERF",
    "bitget_asset": "SLERFUSDT",
    "blowfin_asset": "SLERF-USDT"
  },
  {
    "asset_name": "OL",
    "bitget_asset": "OLUSDT",
    "blowfin_asset": "OL-USDT"
  },
  {
    "asset_name": "PIXEL",
    "bitget_asset": "PIXELUSDT",
    "blowfin_asset": "PIXEL-USDT"
  },
  {
    "asset_name": "IOTX",
    "bitget_asset": "IOTXUSDT",
    "blowfin_asset": "IOTX-USDT"
  },
  {
    "asset_name": "SUI",
    "bitget_asset": "SUIUSDT",
    "blowfin_asset": "SUI-USDT"
  },
  {
    "asset_name": "AVAX",
    "bitget_asset": "AVAXUSDT",
    "blowfin_asset": "AVAX-USDT"
  },
  {
    "asset_name": "SUSHI",
    "bitget_asset": "SUSHIUSDT",
    "blowfin_asset": "SUSHI-USDT"
  },
  {
    "asset_name": "BOME",
    "bitget_asset": "BOMEUSDT",
    "blowfin_asset": "BOME-USDT"
  },
  {
    "asset_name": "LDO",
    "bitget_asset": "LDOUSDT",
    "blowfin_asset": "LDO-USDT"
  },
  {
    "asset_name": "EOS",
    "bitget_asset": "EOSUSDT",
    "blowfin_asset": "EOS-USDT"
  },
  {
    "asset_name": "ZKJ",
    "bitget_asset": "ZKJUSDT",
    "blowfin_asset": "ZKJ-USDT"
  },
  {
    "asset_name": "SUN",
    "bitget_asset": "SUNUSDT",
    "blowfin_asset": "SUN-USDT"
  },
  {
    "asset_name": "MOCA",
    "bitget_asset": "MOCAUSDT",
    "blowfin_asset": "MOCA-USDT"
  },
  {
    "asset_name": "ETHW",
    "bitget_asset": "ETHWUSDT",
    "blowfin_asset": "ETHW-USDT"
  },
  {
    "asset_name": "COMP",
    "bitget_asset": "COMPUSDT",
    "blowfin_asset": "COMP-USDT"
  },
  {
    "asset_name": "DENT",
    "bitget_asset": "DENTUSDT",
    "blowfin_asset": "DENT-USDT"
  },
  {
    "asset_name": "XAI",
    "bitget_asset": "XAIUSDT",
    "blowfin_asset": "XAI-USDT"
  },
  {
    "asset_name": "NULS",
    "bitget_asset": "NULSUSDT",
    "blowfin_asset": "NULS-USDT"
  },
  {
    "asset_name": "AAVE",
    "bitget_asset": "AAVEUSDT",
    "blowfin_asset": "AAVE-USDT"
  },
  {
    "asset_name": "1INCH",
    "bitget_asset": "1INCHUSDT",
    "blowfin_asset": "1INCH-USDT"
  },
  {
    "asset_name": "PUFFER",
    "bitget_asset": "PUFFERUSDT",
    "blowfin_asset": "PUFFER-USDT"
  },
  {
    "asset_name": "QTUM",
    "bitget_asset": "QTUMUSDT",
    "blowfin_asset": "QTUM-USDT"
  },
  {
    "asset_name": "C98",
    "bitget_asset": "C98USDT",
    "blowfin_asset": "C98-USDT"
  },
  {
    "asset_name": "MOVR",
    "bitget_asset": "MOVRUSDT",
    "blowfin_asset": "MOVR-USDT"
  },
  {
    "asset_name": "MVL",
    "bitget_asset": "MVLUSDT",
    "blowfin_asset": "MVL-USDT"
  },
  {
    "asset_name": "AXS",
    "bitget_asset": "AXSUSDT",
    "blowfin_asset": "AXS-USDT"
  },
  {
    "asset_name": "BSV",
    "bitget_asset": "BSVUSDT",
    "blowfin_asset": "BSV-USDT"
  },
  {
    "asset_name": "T",
    "bitget_asset": "TUSDT",
    "blowfin_asset": "T-USDT"
  },
  {
    "asset_name": "BAT",
    "bitget_asset": "BATUSDT",
    "blowfin_asset": "BAT-USDT"
  },
  {
    "asset_name": "ACE",
    "bitget_asset": "ACEUSDT",
    "blowfin_asset": "ACE-USDT"
  },
  {
    "asset_name": "BAKE",
    "bitget_asset": "BAKEUSDT",
    "blowfin_asset": "BAKE-USDT"
  },
  {
    "asset_name": "CHESS",
    "bitget_asset": "CHESSUSDT",
    "blowfin_asset": "CHESS-USDT"
  },
  {
    "asset_name": "ACT",
    "bitget_asset": "ACTUSDT",
    "blowfin_asset": "ACT-USDT"
  },
  {
    "asset_name": "CFX",
    "bitget_asset": "CFXUSDT",
    "blowfin_asset": "CFX-USDT"
  },
  {
    "asset_name": "ZETA",
    "bitget_asset": "ZETAUSDT",
    "blowfin_asset": "ZETA-USDT"
  },
  {
    "asset_name": "APT",
    "bitget_asset": "APTUSDT",
    "blowfin_asset": "APT-USDT"
  },
  {
    "asset_name": "AGLD",
    "bitget_asset": "AGLDUSDT",
    "blowfin_asset": "AGLD-USDT"
  },
  {
    "asset_name": "LTC",
    "bitget_asset": "LTCUSDT",
    "blowfin_asset": "LTC-USDT"
  },
  {
    "asset_name": "RSR",
    "bitget_asset": "RSRUSDT",
    "blowfin_asset": "RSR-USDT"
  },
  {
    "asset_name": "KAS",
    "bitget_asset": "KASUSDT",
    "blowfin_asset": "KAS-USDT"
  },
  {
    "asset_name": "ROSE",
    "bitget_asset": "ROSEUSDT",
    "blowfin_asset": "ROSE-USDT"
  },
  {
    "asset_name": "FLOW",
    "bitget_asset": "FLOWUSDT",
    "blowfin_asset": "FLOW-USDT"
  },
  {
    "asset_name": "REI",
    "bitget_asset": "REIUSDT",
    "blowfin_asset": "REI-USDT"
  },
  {
    "asset_name": "CETUS",
    "bitget_asset": "CETUSUSDT",
    "blowfin_asset": "CETUS-USDT"
  },
  {
    "asset_name": "FIL",
    "bitget_asset": "FILUSDT",
    "blowfin_asset": "FIL-USDT"
  },
  {
    "asset_name": "CVC",
    "bitget_asset": "CVCUSDT",
    "blowfin_asset": "CVC-USDT"
  },
  {
    "asset_name": "1000XEC",
    "bitget_asset": "1000XECUSDT",
    "blowfin_asset": "1000XEC-USDT"
  },
  {
    "asset_name": "AERGO",
    "bitget_asset": "AERGOUSDT",
    "blowfin_asset": "AERGO-USDT"
  },
  {
    "asset_name": "CLOUD",
    "bitget_asset": "CLOUDUSDT",
    "blowfin_asset": "CLOUD-USDT"
  },
  {
    "asset_name": "ZK",
    "bitget_asset": "ZKUSDT",
    "blowfin_asset": "ZK-USDT"
  },
  {
    "asset_name": "BNB",
    "bitget_asset": "BNBUSDT",
    "blowfin_asset": "BNB-USDT"
  },
  {
    "asset_name": "BAN",
    "bitget_asset": "BANUSDT",
    "blowfin_asset": "BAN-USDT"
  },
  {
    "asset_name": "DOGE",
    "bitget_asset": "DOGEUSDT",
    "blowfin_asset": "DOGE-USDT"
  },
  {
    "asset_name": "RARE",
    "bitget_asset": "RAREUSDT",
    "blowfin_asset": "RARE-USDT"
  },
  {
    "asset_name": "BCH",
    "bitget_asset": "BCHUSDT",
    "blowfin_asset": "BCH-USDT"
  },
  {
    "asset_name": "MAVIA",
    "bitget_asset": "MAVIAUSDT",
    "blowfin_asset": "MAVIA-USDT"
  },
  {
    "asset_name": "SOL",
    "bitget_asset": "SOLUSDT",
    "blowfin_asset": "SOL-USDT"
  },
  {
    "asset_name": "HOT",
    "bitget_asset": "HOTUSDT",
    "blowfin_asset": "HOT-USDT"
  },
  {
    "asset_name": "TAIKO",
    "bitget_asset": "TAIKOUSDT",
    "blowfin_asset": "TAIKO-USDT"
  },
  {
    "asset_name": "MOBILE",
    "bitget_asset": "MOBILEUSDT",
    "blowfin_asset": "MOBILE-USDT"
  },
  {
    "asset_name": "ANKR",
    "bitget_asset": "ANKRUSDT",
    "blowfin_asset": "ANKR-USDT"
  },
  {
    "asset_name": "RVN",
    "bitget_asset": "RVNUSDT",
    "blowfin_asset": "RVN-USDT"
  },
  {
    "asset_name": "POL",
    "bitget_asset": "POLUSDT",
    "blowfin_asset": "POL-USDT"
  },
  {
    "asset_name": "VOXEL",
    "bitget_asset": "VOXELUSDT",
    "blowfin_asset": "VOXEL-USDT"
  },
  {
    "asset_name": "BTC",
    "bitget_asset": "BTCUSDT",
    "blowfin_asset": "BTC-USDT"
  },
  {
    "asset_name": "MAV",
    "bitget_asset": "MAVUSDT",
    "blowfin_asset": "MAV-USDT"
  },
  {
    "asset_name": "AGI",
    "bitget_asset": "AGIUSDT",
    "blowfin_asset": "AGI-USDT"
  },
  {
    "asset_name": "CELO",
    "bitget_asset": "CELOUSDT",
    "blowfin_asset": "CELO-USDT"
  },
  {
    "asset_name": "NEIROETH",
    "bitget_asset": "NEIROETHUSDT",
    "blowfin_asset": "NEIROETH-USDT"
  },
  {
    "asset_name": "ORDI",
    "bitget_asset": "ORDIUSDT",
    "blowfin_asset": "ORDI-USDT"
  },
  {
    "asset_name": "ALT",
    "bitget_asset": "ALTUSDT",
    "blowfin_asset": "ALT-USDT"
  },
  {
    "asset_name": "ACH",
    "bitget_asset": "ACHUSDT",
    "blowfin_asset": "ACH-USDT"
  },
  {
    "asset_name": "ONG",
    "bitget_asset": "ONGUSDT",
    "blowfin_asset": "ONG-USDT"
  },
  {
    "asset_name": "GRASS",
    "bitget_asset": "GRASSUSDT",
    "blowfin_asset": "GRASS-USDT"
  },
  {
    "asset_name": "ETHFI",
    "bitget_asset": "ETHFIUSDT",
    "blowfin_asset": "ETHFI-USDT"
  },
  {
    "asset_name": "FTM",
    "bitget_asset": "FTMUSDT",
    "blowfin_asset": "FTM-USDT"
  },
  {
    "asset_name": "XVG",
    "bitget_asset": "XVGUSDT",
    "blowfin_asset": "XVG-USDT"
  },
  {
    "asset_name": "1000000MOG",
    "bitget_asset": "1000000MOGUSDT",
    "blowfin_asset": "1000000MOG-USDT"
  },
  {
    "asset_name": "DEGEN",
    "bitget_asset": "DEGENUSDT",
    "blowfin_asset": "DEGEN-USDT"
  },
  {
    "asset_name": "COS",
    "bitget_asset": "COSUSDT",
    "blowfin_asset": "COS-USDT"
  },
  {
    "asset_name": "FIO",
    "bitget_asset": "FIOUSDT",
    "blowfin_asset": "FIO-USDT"
  },
  {
    "asset_name": "ONE",
    "bitget_asset": "ONEUSDT",
    "blowfin_asset": "ONE-USDT"
  },
  {
    "asset_name": "NOT",
    "bitget_asset": "NOTUSDT",
    "blowfin_asset": "NOT-USDT"
  },
  {
    "asset_name": "POLYX",
    "bitget_asset": "POLYXUSDT",
    "blowfin_asset": "POLYX-USDT"
  },
  {
    "asset_name": "SEI",
    "bitget_asset": "SEIUSDT",
    "blowfin_asset": "SEI-USDT"
  },
  {
    "asset_name": "JTO",
    "bitget_asset": "JTOUSDT",
    "blowfin_asset": "JTO-USDT"
  },
  {
    "asset_name": "CATI",
    "bitget_asset": "CATIUSDT",
    "blowfin_asset": "CATI-USDT"
  },
  {
    "asset_name": "DAR",
    "bitget_asset": "DARUSDT",
    "blowfin_asset": "DAR-USDT"
  },
  {
    "asset_name": "TRB",
    "bitget_asset": "TRBUSDT",
    "blowfin_asset": "TRB-USDT"
  },
  {
    "asset_name": "XLM",
    "bitget_asset": "XLMUSDT",
    "blowfin_asset": "XLM-USDT"
  },
  {
    "asset_name": "ETH",
    "bitget_asset": "ETHUSDT",
    "blowfin_asset": "ETH-USDT"
  },
  {
    "asset_name": "ONT",
    "bitget_asset": "ONTUSDT",
    "blowfin_asset": "ONT-USDT"
  },
  {
    "asset_name": "VIDT",
    "bitget_asset": "VIDTUSDT",
    "blowfin_asset": "VIDT-USDT"
  },
  {
    "asset_name": "PONKE",
    "bitget_asset": "PONKEUSDT",
    "blowfin_asset": "PONKE-USDT"
  },
  {
    "asset_name": "GOAT",
    "bitget_asset": "GOATUSDT",
    "blowfin_asset": "GOAT-USDT"
  },
  {
    "asset_name": "NTRN",
    "bitget_asset": "NTRNUSDT",
    "blowfin_asset": "NTRN-USDT"
  },
  {
    "asset_name": "OXT",
    "bitget_asset": "OXTUSDT",
    "blowfin_asset": "OXT-USDT"
  },
  {
    "asset_name": "XRP",
    "bitget_asset": "XRPUSDT",
    "blowfin_asset": "XRP-USDT"
  },
  {
    "asset_name": "ATA",
    "bitget_asset": "ATAUSDT",
    "blowfin_asset": "ATA-USDT"
  },
  {
    "asset_name": "STORJ",
    "bitget_asset": "STORJUSDT",
    "blowfin_asset": "STORJ-USDT"
  },
  {
    "asset_name": "JUP",
    "bitget_asset": "JUPUSDT",
    "blowfin_asset": "JUP-USDT"
  },
  {
    "asset_name": "QUICK",
    "bitget_asset": "QUICKUSDT",
    "blowfin_asset": "QUICK-USDT"
  },
  {
    "asset_name": "STRK",
    "bitget_asset": "STRKUSDT",
    "blowfin_asset": "STRK-USDT"
  },
  {
    "asset_name": "THETA",
    "bitget_asset": "THETAUSDT",
    "blowfin_asset": "THETA-USDT"
  }
]

const TradePage = () => {
  const [orderBookData, setOrderBookData] = useState({});
  const [expandedRows, setExpandedRows] = useState({});
  const [loading, setLoading] = useState(true);
  const sellContainerRef = useRef(null);

  useEffect(() => {
     const socket = io("https://blofil.zome.wtf");
    // const socket = io("http://localhost:5468");

    // Fetch initial data for all instruments
    instruments.forEach((instrument) => {
      socket.emit("getAssetData", instrument.asset_name, (response) => {
        console.log("response",response)
        if (response.success) {
          setOrderBookData((prevData) => ({
            ...prevData,
            [instrument.asset_name]: {
              asks: response.data.asks || [],
              bids: response.data.bids || []
            }
          }));
        } else {
          console.error(`Error for ${instrument.asset_name}:`, response.message);
        }
      });
    });

    // Listen for real-time updates
    socket.on("assetDataUpdated", ({ asset_name, data }) => {
      setOrderBookData((prevData) => ({
        ...prevData,
        [asset_name]: data
      }));
    });

    setLoading(false);

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleToggleRow = (assetName) => {
    setExpandedRows((prevExpanded) => ({
      ...prevExpanded,
      [assetName]: !prevExpanded[assetName]
    }));
  };

  useEffect(() => {
    if (sellContainerRef.current) {
      sellContainerRef.current.scrollTop = sellContainerRef.current.scrollHeight;
    }
  }, [orderBookData]);

  return (
    <div style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
      <h1>Trade Page</h1>

      {loading ? (
        <p>Loading data...</p>
      ) : (
        instruments.map((instrument, index) => (
          <div
            key={index}
            style={{
              marginBottom: "20px",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "8px"
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{instrument.asset_name}</div>
              <div style={{ flex: 1, padding: "5px" }}>
                {orderBookData[instrument.asset_name]?.bids?.length > 0 ? (
                  <div>
                    <p>
                      <strong>Top Bid Price:</strong>{" "}
                      {new Intl.NumberFormat().format(
                        orderBookData[instrument.asset_name].bids[0][0]
                      )}
                    </p>
                    <p>
                      <strong>Top Bid Quantity:</strong>{" "}
                      {new Intl.NumberFormat().format(
                        orderBookData[instrument.asset_name].bids[0][1]
                      )}
                    </p>
                  </div>
                ) : (
                  <p>No bid data available</p>
                )}
              </div>
              <div style={{ flex: 1, padding: "5px" }}>
                {orderBookData[instrument.asset_name]?.asks?.length > 0 ? (
                  <div>
                    <p>
                      <strong>Top Ask Price:</strong>{" "}
                      {new Intl.NumberFormat().format(
                        orderBookData[instrument.asset_name].asks[0][0]
                      )}
                    </p>
                    <p>
                      <strong>Top Ask Quantity:</strong>{" "}
                      {new Intl.NumberFormat().format(
                        orderBookData[instrument.asset_name].asks[0][1]
                      )}
                    </p>
                  </div>
                ) : (
                  <p>No ask data available</p>
                )}
              </div>
              <button
                onClick={() => handleToggleRow(instrument.asset_name)}
                style={{
                  padding: "10px",
                  backgroundColor: expandedRows[instrument.asset_name]
                    ? "#FF6961"
                    : "#77DD77",
                  border: "none",
                  borderRadius: "5px",
                  color: "white",
                  cursor: "pointer"
                }}
              >
                {expandedRows[instrument.asset_name] ? "Hide Details" : "Show Details"}
              </button>
            </div>
            {orderBookData[instrument.asset_name] &&
              expandedRows[instrument.asset_name] && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px"
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      border: "1px solid #ddd",
                      padding: "10px",
                      borderRadius: "8px",
                      marginLeft: "20px"
                    }}
                  >
                    <h4>Order Book Live</h4>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "300px"
                      }}
                    >
                      <div
                        ref={sellContainerRef}
                        style={{
                          flex: 1,
                          overflowY: "auto",
                          borderBottom: "1px solid #ccc",
                          paddingBottom: "10px"
                        }}
                      >
                        <h5 style={{ textAlign: "center" }}>Asks</h5>
                        <table style={{ width: "100%", textAlign: "center" }}>
                          <thead>
                            <tr>
                              <th>Price</th>
                              <th>Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderBookData[instrument.asset_name]?.asks?.map(
                              (ask, index) => (
                                <tr key={index}>
                                  <td>{new Intl.NumberFormat().format(ask[0])}</td>
                                  <td>{new Intl.NumberFormat().format(ask[1])}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div style={{ flex: 1, overflowY: "auto", paddingTop: "10px" }}>
                        <h5 style={{ textAlign: "center" }}>Bids</h5>
                        <table style={{ width: "100%", textAlign: "center" }}>
                          <thead>
                            <tr>
                              <th>Price</th>
                              <th>Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderBookData[instrument.asset_name]?.bids?.map(
                              (bid, index) => (
                                <tr key={index}>
                                  <td>{new Intl.NumberFormat().format(bid[0])}</td>
                                  <td>{new Intl.NumberFormat().format(bid[1])}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        ))
      )}
    </div>
  );
};

export default TradePage;
