import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import io from "socket.io-client";

const socket = io("https://socket.tradekia.com");

const TradePage = () => {
  const [data, setData] = useState([]); 
  const [orderBookData, setOrderBookData] = useState({}); 
  const [expandedRows, setExpandedRows] = useState({});

  const sellContainerRef = useRef(null);

  // Fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.tokame.network/dcx/getpair"
        );
        setData(response.data.result);
      } catch (error) {
        console.error("Error fetching pair data:", error);
      }
    };

    fetchData();
  }, []);

  // Use WebSocket to subscribe to order book updates for each pair
  useEffect(() => {
    if (data.length > 0) {
      data.forEach((pair) => {
        socket.emit("join-orderbook", { pair }); // Emit WebSocket event for each pair

        socket.on("depth-update", (update) => {
          console.log("parsedData",update)

          const parsedData = JSON.parse(update);
          setOrderBookData((prevData) => ({
            ...prevData,
            [parsedData.channel]: parsedData, // Update with channel name
          }));
        });
      });

      return () => {
        socket.off("depth-update");
      };
    }
  }, [data]);

  // Handle row expansion toggle
  const handleToggleRow = (pair) => {
    setExpandedRows((prevExpanded) => ({
      ...prevExpanded,
      [pair]: !prevExpanded[pair],
    }));
  };

  // Scroll to the bottom of the ask data container when data updates
  useEffect(() => {
    const handleScrollToBottom = (ref) => {
      if (ref.current) {
        ref.current.scrollTop = ref.current.scrollHeight;
      }
    };

    handleScrollToBottom(sellContainerRef); 
  }, [orderBookData]);

  return (
    <div style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
      <h1>Trade Page</h1>

      {/* Display the data for each trading pair */}
      {data.map((item, index) => (
        <div
          key={index}
          style={{ marginBottom: "20px", padding: "10px", border: "1px solid #ccc" }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>{item}</div>
            <div style={{ flex: 1, padding: "5px" }}>
              {orderBookData[item]?.b?.length > 0 ? (
                <div>
                  <p>Buy Price: {orderBookData[item].b[0][0]}</p>
                </div>
              ) : (
                <p>No bid data available</p>
              )}
            </div>
            <div style={{ flex: 1, padding: "5px" }}>
              <div>
                <p>Sell Price: {orderBookData[item]?.a[orderBookData[item].a.length - 1]?.[0]}</p>
              </div>
            </div>
            <button onClick={() => handleToggleRow(item)}>
              {expandedRows[item] ? "Hide Details" : "Show Details"}
            </button>
          </div>
          {orderBookData[item] ? (
            expandedRows[item] && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    border: "1px solid #ddd",
                    padding: "10px",
                    borderRadius: "8px",
                    marginLeft: "20px",
                  }}
                >
                  <h4>Order Book Live INR</h4>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "300px",
                    }}
                  >
                    <div
                      ref={sellContainerRef} 
                      style={{
                        flex: 1,
                        overflowY: "auto",
                        borderBottom: "1px solid #ccc",
                        paddingBottom: "10px",
                      }}
                    >
                      <h5 style={{ textAlign: "center" }}>Asks</h5>
                      <table style={{ width: "100%", textAlign: "center" }}>
                        <thead>
                          <tr>
                            <th>Price</th>
                            <th>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderBookData[item]?.a?.map((ask, index) => (
                            <tr key={index}>
                              <td>{ask[0]}</td> 
                              <td>{ask[1]}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {/* Bid Data (Bottom) for INR */}
                    <div
                      style={{ flex: 1, overflowY: "auto", paddingTop: "10px" }}
                    >
                      <table style={{ width: "100%", textAlign: "center" }}>
                        <tbody>
                          {orderBookData[item]?.b?.map((bid, index) => (
                            <tr key={index}>
                              <td>{bid[0]}</td> {/* Bid price for INR */}
                              <td>{bid[1]}</td> {/* Bid quantity for INR */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : (
            <p>Loading order book data...</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default TradePage;
